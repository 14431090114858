import React from 'react';
import dynamic from 'next/dynamic';
//import {IProductRecommenderProps}  from './ProductRecommender';
import PropTypes from 'prop-types';
import {ICloudinaryImage, IProductOverview} from '../../types';
const MultipleProductRecommender = dynamic(() => import('./MultipleProductRecommender'));
const SingleProductRecommender = dynamic(() => import('./SingleProductRecommender'));

const IProductRecommenderProps = {
    className: PropTypes.string,
    classNames: PropTypes.string, // contentful misspelling
    contentType: PropTypes.oneOf(['productRecommenderBlock']),
    name: PropTypes.string,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    bodyCopy: PropTypes.string,
    disclaimer: PropTypes.object,
    anchorId: PropTypes.string,
    switchTextAndProduct: PropTypes.bool,
    hasDivider: PropTypes.bool,
    cta: PropTypes.string,
    ctaLink: PropTypes.string,
    singleColorBackground: PropTypes.string,
    whiteText: PropTypes.bool,
    backgroundAsset: ICloudinaryImage,
    mobileBackgroundAsset: ICloudinaryImage,
    productList: PropTypes.arrayOf(IProductOverview),
    productGroup: PropTypes.object,
    seeMoreLabel: PropTypes.object,
    seeLessLabel: PropTypes.object,
    revision: PropTypes.number,
    onClickCallback: PropTypes.func
};

export default function ProductRecommenderManager(props) {
    const { productList } = props;
    if (!productList) {
        return null;
    } else if (productList.length > 1) {
        return (
            <MultipleProductRecommender {...props} />
        );
    } else {
        return (
            <SingleProductRecommender {...props} />
        );
    }
}

ProductRecommenderManager.propTypes = IProductRecommenderProps;